import { ITurmaFullDTO } from '../../../../models/happy-code-api.model';
import { Tags } from '../../../../components/Tags';
import { Link } from 'react-router-dom';
import './class.card.styles.scss';
import useClassCard from './use-class-card.hook';

interface ClassCardProps {
  turma: ITurmaFullDTO;
}

export default function ClassCard({ turma }: ClassCardProps) {
  const { descricao, id, curso, matriculas, horarios } = turma;

  const {
    checkWhatStatusClassIs,
    initialDateFormatted,
    finalDateFormatted,
    differenceInYearsBetweenClassDates,
    hasHorarios,
  } = useClassCard({
    turma,
  });

  return (
    <div className="class-card">
      <div className="class-card__class-card-wrapper">
        <div className="class-card-wrapper__class-title-id">
          <h4 className="class-title-id__class-title">{descricao}</h4>

          <div className="class-title-id__class-id-status">
            <p className="class-id-status__class-id">ID #{id}</p>
            <div className="class-id-status__class-status">
              <div className={checkWhatStatusClassIs().dotClass}></div>
              <p className={checkWhatStatusClassIs().textClass}>
                {checkWhatStatusClassIs().text}
              </p>
            </div>
          </div>
        </div>

        <Tags tipo={curso?.modalidade?.descricao} />

        <p className="class-card-wrapper__matriculation-length">
          <span>{matriculas.length} </span>
          Alunos
        </p>

        <p className="class-card-wrapper__year-quantity">
          {initialDateFormatted} - {finalDateFormatted} (
          {differenceInYearsBetweenClassDates} ano)
        </p>

        <div className="class-card-wrapper__schedules-container">
          {hasHorarios &&
            horarios.map(({ id, diaSemana }) => (
              <div key={id} className="schedules-container__schedule">
                <h5 className="schedule__text">{diaSemana}</h5>
              </div>
            ))}
        </div>
      </div>

      <Link
        className="button-line"
        to={`/turmas/professor/detalhe/${turma.id}`}
      >
        Ver Detalhes
      </Link>
    </div>
  );
}
