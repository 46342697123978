import {
  conteudoMinistradoDataStore
} from '../../../../../../../store/lancamento-aula/step-1/conteudo-ministrado-data.store';
import useFindTipoConteudoMinistrado
  from './hooks/use-find-tipo-conteudo-ministrado.hook';
import {
  useShowTitleInput
} from '../../../../../hooks/use-show-title-input.hook';
import { useEffect } from 'react';
import { useFindConteudo } from './hooks/use-find-conteudo.hook';

export default function useConteudoMinistradoForm() {
  const { conteudoMinistradoData } = conteudoMinistradoDataStore();
  const { tipoConteudoMinistrado, findTipoConteudoMinistrado } =
    useFindTipoConteudoMinistrado();
  const { conteudo, findConteudo } = useFindConteudo();
  const showTitleInput = useShowTitleInput();



  const submitButtonDisabled = () => {
    const { titulo, conteudo, descricao } = conteudoMinistradoData;

    const tituloVazio = showTitleInput && titulo?.trim() === '';
    const descricaoVazia = descricao?.trim() === '';

    return tituloVazio || !conteudo || descricaoVazia;
  };

  useEffect(() => {
    const controller = new AbortController();

    Promise.all([findTipoConteudoMinistrado(), findConteudo()]);

    return () => controller.abort();
  }, [findTipoConteudoMinistrado, findConteudo]);

  return {
    showTitleInput,
    submitButtonDisabled: submitButtonDisabled(),
    tipoConteudoMinistrado,
    conteudo
  };
}
